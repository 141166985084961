import Vue from "vue";
import App from "./App.vue";
import router from "./router";
Vue.config.productionTip = false;
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
Vue.use(ElementUI);
Vue.prototype.$http = axios.create({
  // baseURL: "http://120.78.0.18/interface",
  baseURL: "https://www.chuangruninan.cn/i",
  // baseURL: "http://localhost:10001",
  timeout: 100000,
  // headers: {'X-Custom-Header': 'foobar'}
});
var Vconsole = require("vconsole");
new Vconsole();
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
